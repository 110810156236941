<template>
  <CCol col="12">
    <div class="row">
      <CCol sm="12" class="align-self-center">
        <div class="text-sm-right mt-3 mt-sm-0">
          <CButtonGroup class="file-export">
            <CButton color="secondary" disabled>
              {{ $lang.buttons.export.title }}
            </CButton>
            <CButton
                color="danger"
                v-c-tooltip="$lang.buttons.export.pdf"
                v-on:click="exports(self, 'pdf', module, 'Opportunity Resource', self.editId)">
              <i class="fas fa-file-pdf"></i>
            </CButton>
            <VueJsonToCsv
                class="btn btn-success"
                v-c-tooltip="$lang.buttons.export.excel"
                :json-data="json_data"
                :labels="json_label"
                :csv-title="json_title">
              <i class="fas fa-file-excel"></i>
            </VueJsonToCsv>
          </CButtonGroup>
        </div>
      </CCol>
    </div>
    <v-server-table
        :columns="columns"
        :url="listUrl"
        :options="options"
        ref="myTable"
        @loaded="exports(self, 'Excel', module, 'Opportunity Resource', self.editId)">
      <template #actions="data">
        &nbsp;
        <CButton
            color="primary"
            v-c-tooltip="$lang.opportunity_resource.crud.view"
            v-on:click="viewResponse(data.row.id)"
        >
          <i class="fas fa-eye"></i>
        </CButton>
      </template>
    </v-server-table>
  </CCol>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  OpportunityResponseList
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "OpportunityResource",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      editId: "",
      alertMessage: "test",
      showAlert: false,
      self: this,
      activePage: 1,
      listUrl: "",
      module: OpportunityResponseList,
      columns: ["title", "description", "noOfResource", "minBudget", "maxBudget", "actions"],
      data: [],
      options: {
        headings: {
          title: this.$lang.opportunity_resource.table.title,
          description: this.$lang.opportunity_resource.table.description,
          noOfResource: this.$lang.opportunity_resource.table.noOfResource,
          minBudget: this.$lang.opportunity_resource.table.minBudget,
          maxBudget: this.$lang.opportunity_resource.table.maxBudget,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [],
        filterable: [],
      },
    };
  },
  created() {
    let self = this;
    const id = this.$route.params.id;
    self.editId = id;
    self.listUrl = "/opportunities/resource/list/" + id;
  },
  mounted() {
    store.commit("showLoader", false);
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    viewResponse(item) {
      this.$router.push({path: `/opportunities/resource/view/` + item});
    },
  },
};
</script>
