<template>
  <div>
    <p
        class="custome-detail"
        style="cursor: pointer"
        v-on:click="descriptionModel"
    >
      {{ trimfunction(content, length) }}
    </p>
    <CModal
        :title="title"
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
    >
      {{ content }}
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
  </div>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";

export default {
  name: "DescriptionItem",
  mixins: [Mixin],
  components: {},
  props: ["title", "content", "length"],
  data() {
    return {
      largeModal: false,
      self: this,
    };
  },
  methods: {
    descriptionModel() {
      let self = this;
      self.largeModal = true;
    },
  },
};
</script>
