<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CCol>
            <strong>{{ $lang.opportunity.crud.detail }}</strong>
            <back-to-list></back-to-list>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span @click="selected = 'OpportunityEdit'">
              <CTab>
                <template slot="title">
                  {{ $lang.opportunity.crud.detail }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'OpportunityMedia'">
              <CTab>
                <template slot="title">
                  {{ $lang.opportunity.crud.view_media }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'OpportunityResponse'">
              <CTab>
                <template slot="title">
                  {{ $lang.opportunity.crud.view_response }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'OpportunityResource'">
              <CTab>
                <template slot="title">
                  {{ $lang.opportunity.crud.opportunity_resource }}
                </template>
              </CTab>
            </span>
            <span @click="selected = 'OpportunityReport'">
              <CTab>
                <template slot="title">
                  {{ $lang.opportunity.crud.view_report }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component
              :is="selected"
              @changeSelected="getTab($event)"
              style="margin-top: 20px"
          ></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import {Mixin} from "/src/mixins/index.js";
import OpportunityMedia from "./tab/OpportunityMedia";
import OpportunityEdit from "./tab/OpportunityEdit";
import OpportunityResource from "./tab/OpportunityResource";
import OpportunityReport from "./tab/OpportunityReport";
import OpportunityResponse from "./tab/OpportunityResponse";
import BackToList from "/src/views/backToList.vue";

export default {
  name: "OpportunityView",
  mixins: [Mixin],
  components: {
    OpportunityMedia,
    OpportunityEdit,
    OpportunityReport,
    OpportunityResponse,
    OpportunityResource,
    BackToList,
  },
  data() {
    return {
      userId: "",
      tabs: ["OpportunityEdit", "OpportunityMedia", "OpportunityReport", "OpportunityResponse", "OpportunityResource"],
      selected: "OpportunityEdit",
      currentId: "",
      backToListFlag: "",
    };
  },
  mounted() {
    let self = this;
    const id = this.$route.params.id;
    self.userId = this.$route.params.ids;
    self.currentId = id;
    // const testString = this.$router.history.current.path;
    // let pathString =  this.getStringBetween(testString, 'all','view');
    // let entity = pathString.replace("/", "");
    // localStorage.setItem("activeTab",entity);
    // if(this.getStringBetween(testString, 'all','view')!=0){
    //     this.backToListFlag = true;
    // } else {
    //   this.backToListFlag = false;
    // }
  },
  methods: {
    backToList(userId) {
      if (userId != null) {
        this.$router.push({path: "/profile/all/" + userId});
      }
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
